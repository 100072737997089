/* Tables */

.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: $font-weight-medium;
      font-weight: initial;

      i {
        margin-left: 0.325rem;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;

    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  &.table-borderless {
    border: none;

    tr,
    td,
    th {
      border: none;
    }
  }
}
.table > :not(:last-child) > :last-child > *, .jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: $border-color;
}
.table > :not(:first-child), .jsgrid .jsgrid-table > :not(:first-child) {
  border-top: none;
}