/* Forms */

.form-group {
  margin-bottom: 1.75rem;
}
.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    border-color: $border-color;
    padding: $input-padding-y .75rem;
    color: $input-placeholder-color;
    height: 100%;
    background-color: transparent;
  }
  .btn {
    height: 100%;
  }
}
.form-control {
  border: 1px solid $border-color;
  font-family: $type1;
  font-size: $input-font-size;
  background: transparent;
  &:focus {
    background: transparent;
  }
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    appearance: none;
    background: url("../../../assets/images/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: 99% center;

    &:focus {
      outline: 1px solid $border-color;
    }

    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;

        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
  &#sortingField {
    width: 150px !important;
  }

  &.form-select {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    appearance: none;
    background: url("../../../assets/images/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: 99% center;

    &:focus {
      outline: 1px solid $border-color;
    }

    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;

        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}
.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: $danger;
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
